@import "bulma/sass/utilities/initial-variables.sass";
@import "~antd/dist/antd.dark.css";


//  Scoll bar
$primary: black;
$scroll-width: 10px;
$scrolltrack-bg: $primary;
$scrollthumb-bg: white;
$scrollthumb-border-radius: 20px;
$scrollthumb-border: 2.5px solid $primary;

body::-webkit-scrollbar {
  width: $scroll-width; }

body::-webkit-scrollbar-track {
  background: $scrolltrack-bg; }

body::-webkit-scrollbar-thumb {
  background-color: $scrollthumb-bg;
  border-radius: $scrollthumb-border-radius;
  border: $scrollthumb-border; }

/// Content Wrapper
.site-layout {
  .site-layout-background {
    background: #fff; }
  .site-layout-background-dark {
    background: #000; } }
.content-wrapper {
  padding: 1% 10%;
  margin-bottom: 2%;
  height: fit-contentw; }
.content-wrapper-margin-less {
  margin: 0; }

.dark-divider {
  border-top-color: black; }

// FAQ
[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel, .site-collapse-custom-collapse .site-collapse-custom-panel {
  overflow: hidden;
  background: #f7f7f7; }
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: black; }

@import '~bulma/css/bulma.css';

// Pagination
.ant-pagination-item, .ant-pagination-prev, .ant-pagination-next {
  background-color: black !important; }

// Logo
.logo-img {
  max-width: 160px; }
